import React, { useState } from 'react';
import { 
  Box, 
  FormControl, 
  FormLabel, 
  Input, 
  Button, 
  Heading, 
  VStack, 
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { saveAs } from 'file-saver';
import ProgressBar from './ProgressBar';

function TranscriptDownloader() {
  const [url, setUrl] = useState('');
  const [hearingName, setHearingName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [diarizedData, setDiarizedData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('');
  const [ws, setWs] = useState(null);
  const toast = useToast();

  // Expose diarization data to window for debugging
  React.useEffect(() => {
    if (diarizedData) {
      window.currentDiarization = diarizedData;
      console.log('Diarization data available in window.currentDiarization');
    }
  }, [diarizedData]);

  const setupWebSocket = (videoId) => {
    const wsUrl = `wss://api.advocaide.app/ws/${videoId}`;
    console.log('Setting up WebSocket connection to:', wsUrl);
    
    try {
      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log('WebSocket connection established');
        setStatus('Connected to server');
      };

      newWs.onmessage = (event) => {
        try {
          console.log('Received WebSocket message:', event.data);
          const data = JSON.parse(event.data);
          if (data.cpu_percent) {
            setStatus(`Processing: CPU ${data.cpu_percent.toFixed(1)}%, Memory: ${data.memory_mb.toFixed(1)}MB`);
          }
          if (data.total_minutes) {
            setProgress((data.total_minutes / 60) * 100);
            setStatus(`Processed ${data.total_minutes.toFixed(1)} minutes, Found ${data.speakers_count} speakers`);
          }
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };

      newWs.onerror = (error) => {
        console.error('WebSocket error:', error);
        setStatus('Error connecting to server');
      };

      newWs.onclose = (event) => {
        console.log('WebSocket closed:', event);
        setStatus('Connection closed');
        // Attempt to reconnect after 5 seconds
        setTimeout(() => {
          if (loading) {  // Only reconnect if still processing
            console.log('Attempting to reconnect WebSocket...');
            setupWebSocket(videoId);
          }
        }, 5000);
      };

      setWs(newWs);
    } catch (error) {
      console.error('Error setting up WebSocket:', error);
      setStatus('Error setting up connection');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setProgress(0);
    setStatus('Starting process...');

    try {
      // Extract video ID and set up WebSocket
      const videoId = url.split('v=')[1] || url.split('/').pop();
      setupWebSocket(videoId);

      // Get the diarized transcript with speaker identification
      const diarizedResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/diarized-transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: url,
          hearingName: hearingName || 'Untitled Hearing'
        })
      });

      if (!diarizedResponse.ok) {
        throw new Error(`Failed to get diarized transcript: ${diarizedResponse.statusText}`);
      }

      const diarizedData = await diarizedResponse.json();
      setDiarizedData(diarizedData);

      toast({
        title: "Success",
        description: "Transcript processed successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      if (ws) {
        ws.close();
      }
    }
  };

  const handleSaveDiarization = () => {
    if (!diarizedData) return;

    const videoId = new URL(url).searchParams.get('v');
    const data = {
      video_id: videoId,
      hearing_name: diarizedData.hearing_name,
      raw_transcript: diarizedData.raw_transcript,
      diarized_segments: diarizedData.diarized_segments,
      final_transcript: diarizedData.final_transcript,
      speaker_map: diarizedData.speaker_map,
      timestamp: diarizedData.timestamp
    };

    const blob = new Blob([JSON.stringify(data, null, 2)], {type: 'application/json'});
    saveAs(blob, `diarization_${videoId}.json`);
    
    toast({
      title: "Success",
      description: "Diarization results saved!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSaveReadableTranscript = () => {
    if (!diarizedData?.final_transcript) return;

    const videoId = new URL(url).searchParams.get('v');
    
    // Create a formatted transcript string
    const formattedTranscript = diarizedData.final_transcript.map(segment => {
      const timestamp = `[${Math.floor(segment.start)}s - ${Math.floor(segment.end)}s]`;
      return `${segment.speaker}:\n${segment.text}\n${timestamp}\n`;
    }).join('\n');

    // Add header information
    const header = `Hearing Name: ${diarizedData.hearing_name}\nVideo ID: ${videoId}\nDate: ${new Date().toLocaleString()}\n\nTranscript:\n\n`;
    const fullText = header + formattedTranscript;

    // Save as a text file
    const blob = new Blob([fullText], {type: 'text/plain'});
    saveAs(blob, `${diarizedData.hearing_name || 'transcript'}_${videoId}.txt`);
    
    toast({
      title: "Success",
      description: "Readable transcript saved!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const renderSpeakerSegments = () => {
    if (!diarizedData?.final_transcript) return <Text>No speaker segments available</Text>;

    return diarizedData.final_transcript.map((segment, index) => (
      <Box key={index} mb={4} p={2} borderLeft="2px" borderColor="brand.500" pl={4}>
        <Text fontWeight="bold" color="brand.500">
          {segment.speaker}
        </Text>
        <Text>
          {segment.text}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {`${Math.floor(segment.start)}s - ${Math.floor(segment.end)}s`}
        </Text>
      </Box>
    ));
  };

  const renderRawTranscript = () => {
    if (!diarizedData?.raw_transcript) return <Text>No transcript available</Text>;

    return diarizedData.raw_transcript.map((segment, index) => (
      <Text key={index} mb={2}>
        {segment.text}
      </Text>
    ));
  };

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="lg" mb={6}>
        Hearing Transcript Generator
      </Heading>
      
      <Box maxW="xl" mx="auto">
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} mb={6}>
            <FormControl isRequired>
              <FormLabel>YouTube URL</FormLabel>
              <Input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter YouTube URL"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Hearing Name</FormLabel>
              <Input
                type="text"
                value={hearingName}
                onChange={(e) => setHearingName(e.target.value)}
                placeholder="Enter Hearing Name"
              />
            </FormControl>
            <Button
              colorScheme="brand"
              type="submit"
              width="full"
              isLoading={loading}
              loadingText="Processing"
            >
              Generate Transcript
            </Button>
          </VStack>
          
          {(loading || progress > 0) && (
            <ProgressBar progress={progress} status={status} />
          )}
        </form>

        {diarizedData && (
          <Button
            onClick={handleSaveReadableTranscript}
            colorScheme="green"
            size="sm"
            leftIcon={<DownloadIcon />}
            mb={4}
          >
            Save Speaker Attribution Transcript
          </Button>
        )}

        {error && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {diarizedData && (
          <Box mt={6}>
            <Tabs index={tabIndex} onChange={setTabIndex}>
              <TabList>
                <Tab>Speaker Attribution</Tab>
                <Tab>Raw Transcript</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box maxH="600px" overflowY="auto">
                    {renderSpeakerSegments()}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box maxH="600px" overflowY="auto">
                    {renderRawTranscript()}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TranscriptDownloader;
