import React from 'react';
import { Box, Progress, Text } from '@chakra-ui/react';

const ProgressBar = ({ progress, status }) => {
  return (
    <Box w="full" maxW="md" mx="auto" my={4}>
      <Progress 
        value={Math.max(0, progress)} 
        size="sm" 
        colorScheme="brand" 
        rounded="full"
        hasStripe
        isAnimated
      />
      <Text fontSize="sm" color="gray.600" mt={2} textAlign="center">
        {status}
      </Text>
    </Box>
  );
};

export default ProgressBar;
